<template>
  <div class="tutorial-home">
    <h2>【图文教程】</h2>
  <div>
        <h3>第一步：如何进入私密打 自动分销打印_快递单打印_批量发货</h3>
    <p style="color: rgb(255, 0, 0);font-size:16pt;">【卖家中心入口】</p>
    <p>
      <a
        href="https://work.1688.com/"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style="color: rgb(102, 102, 102)"
        >https://work.1688.com/</a
      >
    </p>
    <p style="color: rgb(255, 0, 0);font-size:16pt;">【订购链接】</p>
    <ul data-list-style="circle">
      <li>
        <span class="" style="color: rgb(51, 51, 51);padding-left: 10px;"
          ><span style="display:inline-block;width:10px;height:10px;background-color:black;border-radius: 50%;margin-right: 15px;"></span>
          订购完成后，登录1688卖家中心，点击左下角我的服务就可以看到已订购服务列表，找到私密打 自动分销打印,点击进入
        </span>
      </li>
    </ul>
    <span>
      <img
        :src="OSS+('/image.png')"
        alt=""
        style="margin: initial; max-width: 100%"
      />
    </span>
  </div>
  <div>
       <h3>第二步： 安装打印插件</h3>
    <p style="margin-bottom: 15px;">
      <span ><span style="display:inline-block;width:10px;height:10px;background-color:black;border-radius: 50%;margin-right: 15px;"></span>进入【私密打_快递单打印】后未安装插件系统会弹出按照提示，点击【立即下载】安装插件即可</span
      >
    </p>
     <span>
      <img
        :src="OSS+('/index_img_0420.png')"
        alt=""
        style="margin: initial; max-width: 100%"
      />
    </span>
  </div>
  <div>
       <h3>第三步： 设置发件人信息</h3>
    <p>
      <span>1、进入到基础设置页面</span
      >
    </p>
     <p>
      <span>2、设置快递单发件人信息并保存</span
      >
    </p>
     <span>
      <img
        :src="OSS+('/setting_img_0420.png')"
        alt=""
        style="margin: initial; max-width: 100%"
      />
     
    </span>
  </div>
  <div>
       <h3>第四步：开通过电子面单</h3>
    <p>
      <span>1、电子面单开通链接：</span>
       <a
        href="https://waybill.wuliu.taobao.com/merchantPay.htm?spm=a1z3x.7846489.0.0.2d8d41bf9PA5kp"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style="color: rgb(102, 102, 102)"
        >https://waybill.wuliu.taobao.com/merchantPay.htm?spm=a1z3x.7846489.0.0.2d8d41bf9PA5kp</a
      >
    </p>
     <p>
      <span>2、打开链接，在电子面单页面，点击申请开通服务。选择好自己要打印的电子面单进行开通。</span
      >
    </p>
  </div>
  <!-- <div>
    <h3>第四步：添加快递单模板</h3>
    <p>
      <span>1、进入批量打印页面，点击快递单设置</span>
    </p>
    <img
        :src="OSS+('/image2.png')"
        alt=""
        style="margin: initial; max-width: 80%"
        />
    <p>
      <span>2、首次进入会提示安装打印插件，点击立即下载后安装打印插件</span>
    </p>
    <img
      :src="OSS+('/image3.png')"
      alt=""
      style="margin: initial; max-width: 100%"
    />
    <p>
      <span>3、安装控件后点击快递单设置进行模版设置（添加模板）</span>
    </p>
    <p><span>①选择快递公司</span></p>
    <p><span>②面单类型：XX电子面单</span> </p>
    <p><span>③面单尺寸：根据实际纸张尺寸选择</span></p>
    <p><span>④确认快递服务类型</span></p>
    <p><span>⑤点击保存按钮</span></p>
  </div> -->
  <div>
    <h3>第五步： 打印订单</h3>
    <p style="font-size: 18px;">1、在批量打印中，选择好筛选订单的条件，点击查询找到需要打印的订单</p>
    <p style="font-size: 18px;">2、选择快递单模版，勾选需要打印的订单，点击打印快递单</p>
    <p>
      <img
        :src="OSS+('/five_img_0420.png')"
        alt=""
        style="margin: initial; max-width: 80%"
      />
      
    </p>
    <p style="font-size: 18px;">3、选择发件人地址点击确定</p>
    <p>
      <img
        :src="OSS+('/image5.png')"
        alt=""
        style="margin: initial; max-width: 80%"
      />
    </p>
    <p style="font-size: 18px;">4、选择打印机、设置好打印份数后点击【打印快递单】</p>
    <img :src="OSS+('/image6.png')" alt="" style="margin: initial; max-width: 80%"
>
  </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.navigation{
  display: none !important;
}
.tutorial-home{
  width: 80%;
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  
}
.tutorial-home > p,span{
  /* font-size: 14pt; */
  font-size: 18px;
}

</style>